import React from 'react'

import { isObject } from '@wicadu/arepa/utils'

import HeadSEO from '@atoms/HeadSEO'
import { TemplateProvider } from '@hooks/useTemplate'
import Consts from '@utils/constants'
import { IPageProps } from '@ts-types/Page'
import { usePageContext } from '@HOCs/Providers/PageContext'
import { PAGES_ID } from '@config/pages/const'
import { LanguageTranslations, Translations } from '@ts-types/Translations'
import PaymentMethodsTemplate from '@templates/Orders/PaymentMethods'
import { getUrlByPageId } from '@utils/getUrlByPageId'

interface Props extends IPageProps {}

const { ORDERS_PAYMENT_METHODS_TRANSLATIONS, HOME_TRANSLATIONS } =
  Consts.translates

export default function OrdersCreateContainer() {
  const { lang } = usePageContext()

  const sections = Object.values(
    ORDERS_PAYMENT_METHODS_TRANSLATIONS[lang].ARTICLE
  ).filter((t): t is Translations => isObject(t))

  const intended =
    ORDERS_PAYMENT_METHODS_TRANSLATIONS[lang].ARTICLE['INTENDED'] || []

  return (
    <TemplateProvider
      value={{
        translate: ORDERS_PAYMENT_METHODS_TRANSLATIONS,
        lang,
        sections,
        intended,
      }}
    >
      <PaymentMethodsTemplate />
    </TemplateProvider>
  )
}

export function Head({ pageContext }: Props) {
  const { lang } = pageContext || {}

  const { organization, logo } = Consts.schemeOrg

  const sectionsList = Object.values(
    ORDERS_PAYMENT_METHODS_TRANSLATIONS[lang].ARTICLE
  )
    ?.filter((t): t is Translations => isObject(t))
    .map((t: LanguageTranslations) => ({
      '@type': 'WebPageElement',
      name: t.TITLE,
      description: t.SEO_DESCRIPTION,
    }))

  return (
    <HeadSEO
      title={ORDERS_PAYMENT_METHODS_TRANSLATIONS[lang].SEO_TITLE as string}
      description={
        ORDERS_PAYMENT_METHODS_TRANSLATIONS[lang].SEO_DESCRIPTION as string
      }
      keywords={
        ORDERS_PAYMENT_METHODS_TRANSLATIONS[lang].SEO_KEYWORDS as string
      }
      lang={lang}
      pageId={PAGES_ID.ORDERS_PAYMENT_METHODS}
      jsonLd={[
        {
          '@context': 'https://schema.org',
          '@type': 'Article',
          headline: ORDERS_PAYMENT_METHODS_TRANSLATIONS[lang].SEO_TITLE,
          description:
            ORDERS_PAYMENT_METHODS_TRANSLATIONS[lang].SEO_DESCRIPTION,
          url: getUrlByPageId(PAGES_ID.ORDERS_PAYMENT_METHODS, lang),
          datePublished: '2025-04-01T00:00:00Z',
          dateModified: '2025-04-01T00:00:00Z',
          publisher: organization.organizationField,
          author: organization.organizationField,
          inLanguage: lang,
          image: logo.logoField,
          isPartOf: {
            '@type': 'WebSite',
            name: HOME_TRANSLATIONS[lang].SEO_TITLE as string,
            url: getUrlByPageId(PAGES_ID.HOME, lang),
          },
          mainEntity: {
            '@type': 'ItemList',
            itemListElement: sectionsList,
          },
          keywords: ORDERS_PAYMENT_METHODS_TRANSLATIONS[lang].SEO_KEYWORDS,
        },
      ]}
    />
  )
}
