import React from 'react'

import useTemplate from '@hooks/useTemplate'
import Layout from '@HOCs/Layout/Layout'
import { LanguageTranslations } from '@ts-types/Translations'
import { PaymentMethodsScreen } from '@ts-types/Pages/Orders/PaymentMethods'

function PaymentMethodsTemplate() {
  const { translate, lang, sections, intended } =
    useTemplate<PaymentMethodsScreen>()

  return (
    <Layout>
      <Layout.ArticleGrid
        withBackArrow
        title={translate[lang].ARTICLE['TITLE'] as string}
        breadcrumbs={[
          {
            name: (translate[lang].BREADCRUMBS as LanguageTranslations)[
              'HOME'
            ] as string,
            path: `/${lang}/`,
          },
          {
            name: (translate[lang].BREADCRUMBS as LanguageTranslations)[
              'ORDERS_HOME'
            ] as string,
            path: `/${lang}/orders/`,
          },
        ]}
        intended={intended}
        sections={sections}
        returnUrl={`/${lang}/orders/`}
      />
    </Layout>
  )
}

export default PaymentMethodsTemplate
